<template>
  <NuxtImg v-if="imageStyle"
    :alt="media?.alt.alt"
    :title="media?.title"
    :src="imageUrl"
    sizes="100vw sm:50vw md:400px"
  />
  <img v-else
    :src="imageUrl"
    :alt="media?.alt.alt"
    :title="media?.title" />
</template>

<script lang="ts" setup>
const props = defineProps<{
  media: Object,
  imageStyle: string
}>()

// Get the data.
const imageUrl = !props?.imageStyle ? props?.media?.sourceUrl?.path :  await useGraphqlQuery('imageDerivativeById', {
  id: props?.media?.id,
  derivative: props?.imageStyle.toUpperCase()
}).then((v) => v?.data?.entityById?.image?.derivative?.urlPath)

</script>
