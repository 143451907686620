<template>
  <section :class="paragraphClassList" class="flex flex-col">
    <DesignSystemOrganismsTwoColumnTextElement
      v-for="element in twocoltextelements"
      :pretitle="element.pretitle"
      :title="element.title"
      :subtitle="element.subtitle"
      :text="element.text"
    />
  </section>
</template>

<script lang="ts" setup>
type TwoColumnTextElement = {
  pretitle?: string
  title?: string
  subtitle?: string
  text?: string
}

defineProps<{
  twocoltextelements?: Array<TwoColumnTextElement>
}>()

const { options, parentType } = defineBlokkli({
  bundle: 'icms_layout_2_col_text_elements',
  globalOptions: ['spacing', 'layoutColor'],
})

const paragraphClassList = computed(() => {
  const classList = []

  if (options.value.spacing === 'small') {
    classList.push('py-6', 'lg:py-10', 'gap-6', 'lg:gap-10')
  } else if (options.value.spacing === 'large') {
    classList.push('py-12', 'lg:py-20', 'gap-12', 'lg:gap-20')
  }

  if (options.value.layoutColor === 'light') {
    classList.push('bg-gray-50')
  } else if (options.value.layoutColor === 'primary') {
    classList.push('bg-primary-950', 'text-white')
  } else if (options.value.layoutColor === 'secondary') {
    classList.push('bg-primary-400', 'text-white')
  }

  return classList
})
</script>
