<template>
  <a class="font-bold py-2 px-4 rounded" :class="buttonClass" 
    :target="targetBlank ? '_blank' : '_self'" 
    :href="linkComplete" 
    :title="title"
    v-text="label"
  ></a>
</template>
  
<script lang="ts" setup>
const props = defineProps<{ 
  label?: string,
  link?: string,
  params?: string,
  style?: string,
  targetBlank?: boolean
}>()

const buttonClass = computed(() => {
 if (props?.style === 'normal') {
   return ['bg-gray-200']
 }

 if (props?.style === 'focus') {
   return ['bg-primary-400', 'text-white']
 }

 if (props?.style === 'minimal') {
  return ['underline', 'px-0']
 }
  
})

const linkComplete = computed(() => {
  let link = ''

  if (props?.link) {
    link += props?.link
  }
  if (props?.params) {
    link += props?.params
  }
  return link
})

</script>
