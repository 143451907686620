<template>
  <section :class="paragraphClassList">
    <div class="container mx-auto grid grid-cols-12 gap-6">
      <div class="col-span-12">
        <DesignSystemOrganismsTextLinkBlock :pretitle="pretitle" :title="title" :subtitle="subtitle" />
      </div>
      <div :class="textColumnClassList">
        <DesignSystemOrganismsTextLinkBlock :text="text" :links="buttons" />
      </div>
      <div :class="imageColumnClassList">
        <div v-for="element in media">
          <MediaImage v-if="element.type == 'MediaImage'" :media="element" />
          <MediaIcon v-if="element.type == 'MediaIcon'" :media="element" />
          <MediaRemoteVideo v-if="element.type == 'MediaRemoteVideo'" :media="element" />
          <MediaVideo v-if="element.type == 'MediaVideo'" :media="element" />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
defineProps<{
  pretitle?: string,
  title?: string,
  subtitle?: string
  text?: string
  buttons?: Array<Object>
  media?: Array<Object>
}>()

const { options, parentType } = defineBlokkli({
  bundle: 'icms_layout_text_media',
  globalOptions: ['textWidth', 'textAlign', 'spacing', 'layoutColor']
})

const paragraphClassList = computed(() => {

  const classList = []

  if (options.value.spacing === 'small') {
    classList.push('py-6', 'lg:py-10')
  } else if (options.value.spacing === 'large') {
    classList.push('py-12', 'lg:py-20')
  }

  if (options.value.layoutColor === 'light') {
    classList.push('bg-gray-50')
  } else if (options.value.layoutColor === 'primary') {
    classList.push('bg-primary-950', 'text-white')
  } else if (options.value.layoutColor === 'secondary') {
    classList.push('bg-primary-400', 'text-white')
  }

  return classList
})

const textColumnClassList = computed(() => {

  const classList = []
  if (options.value.textAlign === 'right') [
    classList.push('order-last')
  ]

  if (options.value.textWidth === 'medium') {
    classList.push('col-span-6')
  } else if (options.value.textWidth === 'narrow') {
    classList.push('col-span-4')
  }
  else {
    classList.push('col-span-8')
  }
  return classList
})

const imageColumnClassList = computed(() => {
  if (options.value.textWidth === 'medium') {
    return ['col-span-6']
  } else if (options.value.textWidth === 'narrow') {
    return ['col-span-8']
  }
  return ['col-span-4']
})

</script>
