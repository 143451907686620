<template>
  <section :class="paragraphClassList">
    <div class="relative flex items-center hero-image-wrapper">
      <div class="absolute inset-0">
        <MediaImage class="w-full h-full object-cover" v-if="medium?.type == 'MediaImage'" :media="medium" />
        <MediaVideo class="w-full h-full object-cover" v-if="medium?.type == 'MediaVideo'" :media="medium"
          :controls="null" autoplay muted loop />
        <div class="absolute inset-0"></div>
      </div>
      <div v-if="pretitle || title || subtitle"
        class="content-area relative container mx-auto grid grid-cols-12 gap-6 px-0 py-12 md:py-32 lg:py-32 z-10">
        <div class="col-span-12 p-8 md:col-start-8 text-black">
          <DesignSystemOrganismsTextLinkBlock 
          :pretitle="pretitle" 
          :title="title" 
          :subtitle="subtitle" 
          :text="text"
          :links="buttons" />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>

defineProps<{
  pretitle?: string,
  title?: string,
  subtitle?: string
  text?: string
  buttons?: Array<Object>
  medium?: Object
}>()

const { options, parentType } = defineBlokkli({
  bundle: 'icms_layout_hero_image',
  options: {
    colorMode: {
      type: 'radios',
      label: 'Color mode',
      default: 'unset',
      displayAs: 'colors',
      options: {
        'unset': { class: 'bg-transparent', label: 'Unset' },
        'light-mode': { class: 'bg-white', label: 'Light mode' },
        'dark-mode': { class: 'bg-black', label: 'Dark mode' },
      },
    },
  },
})

const paragraphClassList = computed(() => {

  const classList = []

  if (options.value.colorMode !== 'unset') {
    classList.push(options.value.colorMode)
  }

  return classList
})

</script>

<style>

/* Temporary Styling Options*/

.hero-image-wrapper {
 min-height: 700px
}

.content-area a {
  background-color: #111111;
  color: #ffffff;
}

.light-mode .hero-image-wrapper:before {
  content: "";
  background: linear-gradient(to right, transparent 25%, rgba(255, 255, 255, 0.75) 100%);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.dark-mode .hero-image-wrapper:before {
  content: "";
  background: linear-gradient(to right, transparent 25%, rgba(17, 17, 17, 0.75) 100%);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

@media (max-width: 1023px) {
  .light-mode .hero-image-wrapper:before {
    background: rgba(255, 255, 255, 0.3); 
  }

  .dark-mode .hero-image-wrapper:before {
    background: rgba(17, 17, 17, 0.3); 
  }

  .hero-image-wrapper {
    min-height: 400px
  }
}

.dark-mode .content-area * {
  color: #ffffff;
}

.dark-mode .content-area a {
  background-color: #ffffff;
  color: #111111;
}

</style>