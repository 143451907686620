<template>
  <VuepalRemoteVideo v-slot="{ embedUrl }" :url="media.link">
    <figure class="w-full max-w-[1920px]">
      <iframe
        v-if="embedUrl && isPlaying"
        :src="embedUrl"
        :title="media.title"
        allow="autoplay; encrypted-media; picture-in-picture"
        allowfullscreen
        class="aspect-video w-full md:px-40 lg:px-0"
      />

      <button
        v-if="!isPlaying"
        class="relative flex aspect-video w-full cursor-pointer items-center justify-center md:px-40 lg:px-0"
        @click.prevent="isPlaying = true"
      >
        <img
          :src="thumbnailUrl"
          class="absolute left-0 top-0 size-full object-cover"
          :alt="thumbnailAlt"
        />

        <SpriteSymbol name="play" class="z-10 size-[75px]" />
      </button>

    </figure>
  </VuepalRemoteVideo>

</template>

<script lang="ts" setup>
const props = defineProps<{
  media: Object,
}>()


const isPlaying = ref(false)

const thumbnailUrl = computed(() => {
  const file = props.media?.thumbnailOverride || props.media?.thumbnail
  return file?.entity?.uri?.first?.url
})

const thumbnailAlt = computed(() => {
  const file = props.media?.thumbnailOverride || media?.thumbnail
  return file?.alt
})

</script>