<template>
  <section :class="paragraphClassList">
    <div class="container mx-auto grid grid-cols-12 gap-6">
      <div class="col-span-12">
        <DesignSystemOrganismsTextLinkBlock :pretitle="pretitle" :title="title" :subtitle="subtitle" :text="text" />
      </div>
    </div>

    <div class="container mx-auto grid grid-cols-3 gap-6 mt-6">
      <DesignSystemOrganismsTeaserCard v-for="teaser in teasers" 
        :pretitle="teaser?.pretitle" 
        :title="teaser?.title"
        :subtitle="teaser?.subtitle" 
        :text="teaser?.text" 
        :link="teaser?.link.alias" 
        :linkLabel="teaser?.linkLabel"
        :image="teaser?.image"
      />
    </div>

  </section>
</template>

<script lang="ts" setup>
defineProps<{
  pretitle?: string,
  title?: string,
  subtitle?: string
  text?: string
  teasers?: Array<Object>
}>()

const { options, parentType } = defineBlokkli({
  bundle: 'icms_layout_teasers',
  globalOptions: ['spacing', 'layoutColor']
})

const paragraphClassList = computed(() => {

  const classList = []

  if (options.value.spacing === 'small') {
    classList.push('py-6', 'lg:py-10')
  } else if (options.value.spacing === 'large') {
    classList.push('py-12', 'lg:py-20')
  }

  if (options.value.layoutColor === 'light') {
    classList.push('bg-gray-50')
  } else if (options.value.layoutColor === 'primary') {
    classList.push('bg-primary-950', 'text-white')
  } else if (options.value.layoutColor === 'secondary') {
    classList.push('bg-primary-400', 'text-white')
  }

  return classList
})

</script>
