<template>
  <section :class="paragraphClassList">
    <div class="container mx-auto grid grid-cols-12 gap-6">
      <div :class="textWrapperClassList">
        <DesignSystemOrganismsTextLinkBlock
          :pretitle="pretitle" 
          :title="title" 
          :subtitle="subtitle" 
          :text="text"
          :links="buttons"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
defineProps<{ 
  pretitle?: string
  title?: string
  subtitle?: string
  text?: string
  buttons?: Array<Object>
}>()

const { options, parentType } = defineBlokkli({
  bundle: 'icms_layout_text',
  globalOptions: ['textWidth', 'spacing', 'layoutColor'],
})

const paragraphClassList = computed(() => {

  const classList = []

  if (options.value.spacing === 'small') {
    classList.push('py-6', 'lg:py-10')
  } else if (options.value.spacing === 'large') {
    classList.push('py-12', 'lg:py-20')
  }

  if (options.value.layoutColor === 'light') {
    classList.push('bg-gray-50')
  } else if (options.value.layoutColor === 'primary') {
    classList.push('bg-primary-950', 'text-white')
  } else if (options.value.layoutColor === 'secondary') {
    classList.push('bg-primary-400', 'text-white')
  }

  return classList
})

const textWrapperClassList = computed(() => {
  if (options.value.textWidth === 'medium') {
    return ['col-span-8', 'col-start-3']
  } else if (options.value.textWidth === 'narrow') {
    return ['col-span-6', 'col-start-4']
  }
  return ['col-span-12']
})

</script>
