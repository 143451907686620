import type { BlockOptionDefinition } from '#blokkli/types/blokkOptions'

type GlobalOptionsDefaults = {
  type: BlockOptionDefinition['type']
  default: any
}

export const bundlesWithVisibleLanguage: string[] = []
export const bundlesWithHiddenGlobally: string[] = []

export const globalOptionsDefaults: Record<string, GlobalOptionsDefaults> = {
  "bkVisibleLanguages": {
    "default": [],
    "type": "checkboxes"
  },
  "spacing": {
    "default": "small",
    "type": "radios"
  },
  "layoutColor": {
    "default": "white",
    "type": "radios"
  },
  "textWidth": {
    "default": "wide",
    "type": "radios"
  },
  "textAlign": {
    "default": "left",
    "type": "radios"
  }
} as const